


<template>
  <div>
    <mdb-navbar v-show="navbarType == 'regular-fixed'" position="top"  dark color="indigo" name="Your Logo" href="#" scrolling>
      <a href="https://agustinostv-front.archive.tornadocore.es" style="color: white;padding-right: 30px"><b>AGUSTINOS.TV</b></a>

    </mdb-navbar>

    <div style=" margin-bottom: -25px;margin-top:-55px;">
      <div class="view intro-2">
        <div class="full-bg-img" style="height:30% !important;">
          <div class="mask rgba-black-strong" style="padding-top: 24%;padding-bottom: 24%;padding-left: 60px;padding-right: 60px;">


            <b-row v-if="this.esta_en_vertical == false">

              <div class="col-md-6">
                        <h1 class="white-text bold">AGUSTINOS.TV</h1>
                        <p class="white-text">Agustinostv era un servicio privado de video bajo demanda y retransmisiones.</p>
              </div>
              <div class="col-md-3 align-content-center align-center" style="padding-left: 6%;">
                <b-row>
                  <h3 class="white-text">Ya tengo una cuenta</h3>
                </b-row>
                  <b-row size="12" class="text-center mb-5">
                    <mdb-btn @click.native="showModalLogin = true" color="primary"><b>Iniciar Sesión</b></mdb-btn>
                  </b-row>
              </div>
              <div class="col-md-3 align-content-center align-center" style="padding-left: 6%;">
                <b-row>
                  <h3 class="white-text">Quiero registrarme</h3>
                </b-row>
                <b-row size="12" class="text-center mb-5">
                  <mdb-btn @click.native="showModalRegistro = true" color="primary"><b>Registrarme</b></mdb-btn>
                </b-row>
              </div>

            </b-row>

            <!-- <b-row v-if="this.esta_en_vertical == true" style="padding-top: 50px">

              <div class="row" style="width: 100%">
                <h1 class="white-text bold">AGUSTINOS.TV</h1>
                <p class="white-text">Agustinostv es un servicio privado de video bajo demanda y retransmisiones en directo hecho por Agustinos Alicante</p>
              </div>
              <div class="row"  style="width: 100%;padding-top: 10px;padding-bottom: 10px;text-align: center;align-content: center">
                  <center>
                  <mdb-btn @click.native="showModalLogin = true" color="primary"><b>Iniciar Sesión</b></mdb-btn>
                  </center>
              </div>
              <div class="row"  style="width: 100%;padding-top: 10px;padding-bottom: 10px;text-align: center;align-content: center">
                <center>
                <mdb-btn @click.native="showModalRegistro = true" color="primary"><b>Registrarme</b></mdb-btn>
                </center>
              </div>

            </b-row> -->



          </div>
        </div>
      </div>
    </div>
    <mdb-footer  dark color="indigo">
      <p class="footer-copyright mb-0 py-3 text-center">
        &copy; {{new Date().getFullYear()}} Copyright:
        <a
          href="#"
        >AGUSTINOS.TV</a>
        | Versión 3.20.01.01
      </p>
    </mdb-footer>

    <mdb-modal :show="showModalLogin" @close="showModalLogin = false" cascade class="text-left">
      <mdb-modal-header class="primary-color white-text">
        <h4 class="title"><mdb-icon icon="pencil-alt" /> Iniciar Sesión</h4>
      </mdb-modal-header>
      <mdb-modal-body class="grey-text">
        <mdb-input size="sm" label="Correo Electrónico" icon="envelope" v-model="email" group type="email" validate error="wrong" success="right"/>
        <mdb-input size="sm" label="Contraseña" icon="tag" group type="password" v-model="password" validate error="wrong" success="right"/>
      </mdb-modal-body>
      <mdb-modal-footer>
        <b-row v-if="esta_en_vertical == false" style="width:100%">
          <div class="col-md-6">
            <a style="float:left;margin-top: 18px;" href="https://agustinostv-front.archive.tornadocore.es/#/auth/recuperar-cuenta">Recuperar cuenta</a>
          </div>
          <div class="col-md-6">
            <mdb-btn color="primary" @click="Login">Conectarse</mdb-btn>
          </div>


        </b-row>

        <b-row v-if="esta_en_vertical == true" style="width:100%;text-align: center;align-content: center">
          <div class="col-md-12" style="text-align: center;align-content: center">
            <a style="margin-top: 18px;" href="https://agustinostv-front.archive.tornadocore.es/#/auth/recuperar-cuenta">Recuperar cuenta</a>
          </div>
          <div style="padding: 30px"></div>
          <div class="col-md-12">
            <mdb-btn color="primary" @click="Login">Conectarse</mdb-btn>
          </div>


        </b-row>
      </mdb-modal-footer>
    </mdb-modal>

    <mdb-modal :show="showModalRegistro" @close="showModalRegistro = false" cascade class="text-left">
      <mdb-modal-header class="primary-color white-text">
        <h4 class="title"><mdb-icon icon="pencil-alt" /> Registrarse</h4>
      </mdb-modal-header>
      <mdb-modal-body class="grey-text">
        <mdb-input size="sm" label="Nombre de usuario" icon="envelope" v-model="username" group type="text" validate error="wrong" success="right"/>
        <mdb-input size="sm" label="Email" icon="envelope" v-model="email" group type="text" validate error="wrong" success="right"/>
        <mdb-input size="sm" label="Contraseña" icon="envelope" v-model="password" group type="password" validate error="wrong" success="right"/>
        <mdb-input size="sm" label="Repite contraseña" icon="tag" group type="password" v-model="password2" validate error="wrong" success="right"/>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="primary" @click="Registrarse">Registrarse</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>


<script>

import {
  mdbNavbar,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbNavbarBrand,
  mdbFooter,
  mdbTooltip,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbInput,
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbBtn,
  mdbIcon,
} from "mdbvue"
import ApiService from '@/services/api';

export default {
  name: 'Login',
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbNavbarBrand,
    mdbFooter,
    mdbTooltip,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbInput,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbIcon,
  },
  data() {
    return {
      navbarType: 'regular-fixed',
      showModalLogin: false,
      esta_en_vertical: false,
      font_size:'.81rem',
      password:null,
      password2:null,
      showModalRegistro: false,
      content: false,
      email: '',
      username: null,
    }
  },
  mounted() {

    if(this.$route.query.activado)
    {
      this.$swal.fire({
        position: 'center',
        type: 'success',
        title: 'Cuenta activada con éxito',
        text: 'Ya puedes acceder a todas las funciones de Agustinos.TV',
        showConfirmButton: false,
        timer: 3000
      })

    }

    if(window.innerHeight > window.innerWidth)
    {
      this.esta_en_vertical = true;
      document.querySelector("html").style.fontSize = '.80rem';
    }
  },
  created() {
    window.document.title = "AGUSTINOS.TV";


  },
  methods: {
    Login(){

        console.log('haciendo login ...');
        ApiService.login(this.email, this.password).then(response => {
          console.log(response);
          if (response.status == 200) {

            if (response.data && response.data.access_token) {
              localStorage.setItem('token', response.data.access_token);
              console.log(localStorage.getItem('token'));
              this.$router.push({path: '/'});
              this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Conectado con éxito',
                showConfirmButton: false,
                timer: 1500
              })
            } else {
              console.log('aaaa');
            }
          }
        }).catch(error => {
            this.showModal = false;
            this.$swal.fire({
              title: 'Ha habido un error al iniciar sesión',
              text: 'Datos incorrectos o usuario no activado',
              type: 'error',
              showCancelButton: false,
            });
        });


    },
    Registrarse(){

;
      if(this.username != null && this.username.length > 0 && this.email != null && this.email.length > 0 && this.password != null && this.password2 != null && this.password.length > 0 && this.password2.length > 0 && this.password == this.password2) {
        ApiService.registrar(this.username, this.email, this.password).then(response => {
          console.log(response);
          if (response.status == 200) {
            this.$swal.fire({
              position: 'center',
              type: 'success',
              title: 'Registro completado',
              text: 'Revisa tu correo electrónico para activar tu cuenta',
            })
          }
        }).catch((error) => {
          this.showModal = false;
          this.$swal.fire({
            title: 'Ha habido un error al intentar registrarte',
            text: 'Puede ser un problema de red o que el usuario ya esté registrado',
            type: 'error',
            showCancelButton: false,
          });
        });

      }
      else
      {
        this.$swal.fire({
          title: 'Error de datos',
          text: 'Introduce todos los datos pedidos y confirma tu contraseña 2 veces escribiéndola igual',
          type: 'error',
          showCancelButton: false,
        });

      }

    }

  }
}


</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}
.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.demo-section {
  padding: 20px 0;
}
.demo-section > section {
  border: 1px solid #e0e0e0;
  padding: 15px;
}
.demo-section > h4 {
  font-weight: bold;
  margin-bottom: 20px;
}
.demo-title {
  color: #9e9e9e;
  font-weight: 700;
  margin-bottom: 0;
  padding-left: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.view{
    background: url("https://mdbootstrap.com/img/Photos/Others/img (42).jpg") no-repeat center center;
    background-size: cover;
    height: 100vh;
}
</style>
